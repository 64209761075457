import Marquee from 'react-fast-marquee';
import { studentEvals } from '../studentEvals';
import styles from './Teaching.module.css';
import { teaching } from '../data';

const Teaching = () => {
  return (
    <section className={styles.container}>
       <h3 className={styles.listTitle}>lecturing</h3>
      {teaching.map(list =>
        <div className={styles.teachList}>
        {list.samples.map(sample =>
          <div className={styles.teachCard}>
            <div className={styles.schoolDeets}>
              <img src={sample.imgSrc} alt={sample.imgTitle} className={styles.logo} />
              <h2>{sample.institution}</h2>
              <h4>{sample.department}</h4>
            </div>
            <div className={styles.jobDeets}>
              <h4>{sample.details}</h4>
              <ul>
              {sample.courses.map(course => 
                <li>{course}</li>
              )}
              </ul>
            </div>
          </div>
        )}
        </div>
      )}
      

      <div className={styles.slideContainer}>
        <h3 className={styles.listTitle}>sample lecture calendar & slides</h3>
        <div className={styles.slideList}>
          <iframe src="/assets/10BDisneyCalendar.pdf" title='DAIcal' className={styles.slides} />
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT0GaY4UrH2IU16uNCu4neScgIfAH9TIA1_JLUuQi5Xohs4o_97826Cwz9LZmkotg/embed?start=false&loop=false&delayms=3000" title='DAIwk2' className={styles.slides} />
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTZdLM9D9DrYRS-SXed_1ZiYptp3Z-fIWzfd5T9DdQ1i-wDJ8-rucsl4lzvHFQvfA/embed?start=false&loop=false&delayms=3000" title='DAIwk4' className={styles.slides} />
        </div>
      </div>

      <div className={styles.evalContainer}>
        <h3 className={styles.listTitle}>student evaluations</h3>
        <div className={styles.evalTicker}>
          <img src="/assets/EvalFace.PNG" alt="Surprised Cartoon" className={styles.evalImg}/>
          <Marquee className={styles.marquee} speed={35} pauseOnHover={true}>
            {studentEvals.map(studentEval => 
              <p className={styles.marqueeText}>{studentEval}</p>
            )}
          </Marquee>
        </div>
      </div>
      

    </section>
    
  )
}

export default Teaching;