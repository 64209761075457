export const artProjects = [
  {
      listTitle: "single panel",
      tags: ['all', 'illustration'],
      samples: [
        {
          title: "Skyscraper Pinata",
          image: "/assets/nyer/SkyscraperPinata.PNG",
        },
        {
          title: "Bakeoff Boxer",
          image: "/assets/nyer/BakeoffBoxer.PNG",
        },
        {
          title: "Millenial Weight Blanket",
          image: "/assets/nyer/MillenialWeightBlanket.PNG",
        },
        {
          title: "Ant Waiter",
          image: "/assets/nyer/AntWaiter.PNG",
        },
        {
          title: "Good Enough",
          image: "/assets/nyer/GoodEnough.png",
        },
        {
          title: "Handgun",
          image: "/assets/nyer/Handgun.png",
        },
        {
          title: "Finest Condiments",
          image: "/assets/nyer/Finest_Condiments.jpg",
        },
        {
          title: "Frost the Delayed Snowman",
          image: "/assets/nyer/Frosty_The_Delayed_Snowman.jpg",
        },
        {
          title: "Human Shoes",
          image: "/assets/nyer/Human_Shoes.jpg",
        },
        {
          title: "Mr Owl In Billing",
          image: "/assets/nyer/Mr Owl In Billing.png",
        },
        {
          title: "Prince of Darkness Yoga",
          image: "/assets/nyer/Prince_Of_Darkness_Yoga.jpg",
        },
        {
          title: "Really No Gifts",
          image: "/assets/nyer/Really No Gifts.png",
        },
        {
          title: "Rigoletto Heist",
          image: "/assets/nyer/Rigoletto_Heist.jpg",
        },
        {
          title: "Siege Diet",
          image: "/assets/nyer/Siege_Diet.jpg",
        },
        {
          title: "Cowlick Dracula",
          image: "/assets/nyer/CowlickDracula.PNG",
        },
        {
          title: "Critical Bogeyman",
          image: "/assets/nyer/CriticalBogeyman.PNG",
        },
        {
          title: "Lets Never",
          image: "/assets/nyer/LetsNever.PNG",
        },
        {
          title: "Metal Todds Dad",
          image: "/assets/nyer/MetalToddsDad.PNG",
        },
        {
          title: "Plain Vanilla",
          image: "/assets/nyer/PlainVanilla.png",
        },
        {
          title: "Soul Pets",
          image: "/assets/nyer/SoulPets.PNG",
        },
        {
          title: "Microplastics",
          image: "/assets/nyer/Microplastics.png",
        },
        {
          title: "Spider Lawyer",
          image: "/assets/nyer/SpiderLawyer.PNG",
        },
        {
          title: "Swipe Face",
          image: "/assets/nyer/SwipeFace.PNG",
        },
        {
          title: "Lady of the Lake",
          image: "/assets/nyer/LadyOfTheLake.png",
        },
        {
          title: "Curriculum Inhibitor",
          image: "/assets/nyer/CurriculumInhibitor.PNG",
        },
        {
          title: "Frankensteins Provider",
          image: "/assets/nyer/FrankensteinsProvider.PNG",
        },
        {
          title: "That Pontiac",
          image: "/assets/nyer/ThatPontiac.PNG",
        },
        {
          title: "Community Chest",
          image: "/assets/nyer/CommunityChest.png",
        },
        {
          title: "Uncle Sam Overlords",
          image: "/assets/nyer/UncleSamOverlords.PNG",
        },
        {
          title: "Victim Vacation",
          image: "/assets/nyer/VictimVacation.PNG",
        },
        {
          title: "Werewolf Haircut",
          image: "/assets/nyer/WerewolfHaircut.PNG",
        },
        {
          title: "You Were Saying",
          image: "/assets/nyer/YouWereSaying.PNG",
        },
      ]
  },
  {
      listTitle: "dnd comics",
      tags: ['all', 'illustration', 'dnd'],
      samples: [
        {
          title: "Animal Friendship",
          image: "/assets/dndcomics/AnimalFriendship.PNG",
        },
        {
          title: "Hunters Mark Meeting",
          image: "/assets/dndcomics/HuntersMarkMeeting.PNG",
        },
        {
          title: "Lesser Restoration Weekend",
          image: "/assets/dndcomics/LessRestorationWeekend.PNG",
        },
        {
          title: "Mage Hand Dad",
          image: "/assets/dndcomics/MageHandDad.PNG",
        },
        {
          title: "Persuasion Check Interview",
          image: "/assets/dndcomics/PersuasionCheckInterview.PNG",
        },
        {
          title: "Toddler Identify",
          image: "/assets/dndcomics/ToddlerIdentify.PNG",
        },
        {
          title: "Cyclops Constable",
          image: "/assets/dndcomics/Constable_Clavin.jpg",
        },
      ]
  },
  {
    listTitle: "collabs with my kiddos",
    tags: ['all', 'collabs', 'illustration'],
    samples: [
      {
        title: "Lizard Monster Truck",
        image: "/assets/kidmonsters/Dumpstern.jpg",
      },
      {
        title: "Shark Monster",
        image: "/assets/kidmonsters/Crycry.jpg",
      },
      {
        title: "Axolotl Owl",
        image: "/assets/kidmonsters/Mew.jpg",
      },
      {
        title: "Robot Chameleon",
        image: "/assets/kidmonsters/Niang_The_Robot_Chameleon.jpg",
      },
      {
        title: "Chameleon Hydra",
        image: "/assets/kidmonsters/Rainbowspike.jpg",
      },
      {
        title: "Axolotl Bat Turtle",
        image: "/assets/kidmonsters/Swingtaker.jpg",
      },
      {
        title: "Croc Horse",
        image: "/assets/kidmonsters/Juhjuh_Baby.jpg",
      },
      {
        title: "Snake Turtle Scorpion",
        image: "/assets/kidmonsters/Mryken.jpg",
      },
    ]
  },
  {
      listTitle: "fan art",
      tags: ['all', 'fanart', 'illustration'],
      samples: [
        {
          title: "Greninja Runway",
          image: "/assets/fanart/GreninjaRunway.png",
        },
        {
          title: "Pikachu Garage",
          image: "/assets/fanart/PikachuGarage.png",
        },
        {
          title: "Pokemon TV",
          image: "/assets/fanart/Pokemon_TV.jpg",
        },
        {
          title: "Pokemon Museum",
          image: "/assets/fanart/PokeMuseum.JPG",
        },
        {
          title: "Pokemon House",
          image: "/assets/fanart/PokeHouse.jpg",
        },
        {
          title: "Yusuke Urameshi",
          image: "/assets/fanart/Urameshi.jpg",
        },
        {
          title: "Sesshomaru",
          image: "/assets/fanart/Seshomaru.jpg",
        },
        {
          title: "NSync Family Guy Album Cover",
          image: "/assets/fanart/NoStrings.jpg",
        },
      ]
  },
  {
      listTitle: "webcomic: it came from hugo pines!",
      tags: ['all', 'illustration'],
      samples: [
        {
          title: "Face Lineup",
          image: "/assets/hugopines/HPlineup.JPG",
        },
        {
          title: "Chapter 05",
          image: "/assets/hugopines/ICFHP_ch05.PNG",
        },
        {
          title: "Chapter 06",
          image: "/assets/hugopines/ICFHP_ch06.PNG",
        },
        {
          title: "Chapter 08",
          image: "/assets/hugopines/ICFHP_ch08.PNG",
        },
        {
          title: "Chapter 12",
          image: "/assets/hugopines/ICFHP_ch12.PNG",
        },
        {
          title: "Chapter 38",
          image: "/assets/hugopines/ICFHP_ch38.PNG",
        },
        {
          title: "Chapter 39",
          image: "/assets/hugopines/ICFHP_ch39.PNG",
        },
        {
          title: "Chapter 40",
          image: "/assets/hugopines/ICFHP_ch40.PNG",
        },
        {
          title: "Chapter 44",
          image: "/assets/hugopines/ICFHP_ch44.PNG",
        },
      ]
  },
  {
      listTitle: "software dev & ui design",
      tags: ['all', 'software dev'],
      samples: [
        {
          title: "Vakay!",
          image: "/assets/UIwork/VakayUI.png",
        },
        {
          title: "Emotional Wellbeing Lab",
          image: "/assets/UIwork/ewb-lab-UI.png",
        },
        {
          title: "5e Monster Manual",
          image: "/assets/UIwork/MonsterManual-UI.png",
        },
        {
          title: "Subscriber",
          image: "/assets/UIwork/SubscriberUI.png",
        },
        {
          title: "ifGift",
          image: "/assets/UIwork/ifGiftUI.png",
        },
      ]
  },
  {
      listTitle: "logo design",
      tags: ['all', 'software dev'],
      samples: [
        {
          title: "Vakay!",
          image: "/assets/logos/VakayLogo.png",
        },
        {
          title: "Subscriber",
          image: "/assets/logos/SubscriberLogo.png",
        },
        {
          title: "ifGift",
          image: "/assets/logos/ifGiftLogo.png",
        },
      ]
  },
];


export const writingProjects = [
  {
    listTitle: "pilot scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "fantastic four spec pilot",
        image: "/assets/scripts/FFpilot.pdf",
      },
      {
        title: "the book was better",
        image: "/assets/scripts/BookWasBetter.pdf"
      },
      {
        title: "squatch and the city",
        image: "/assets/scripts/SquatchAndTheCity.pdf"
      },
      {
        title: "monsterology",
        image: "/assets/scripts/Monsterology.pdf"
      }
    ]
  },
  {
    listTitle: "comic spec scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "nessie frump, galactic local 918",
        image: "/assets/scripts/NessieFrump.pdf",
      },
      {
        title: "the red heresy",
        image: "/assets/scripts/TheRedHeresy.pdf",
      },
      {
        title: "in deep space",
        image: "/assets/scripts/InDeepSpace.pdf",
      },
      {
        title: "cleaning the attic",
        image: "/assets/scripts/CleaningTheAttic.pdf",
      },
      {
        title: "delays in parenting",
        image: "/assets/scripts/DelaysInParenting.pdf",
      },
      {
        title: "middle management",
        image: "/assets/scripts/MiddleManagement.pdf",
      },
    ]
  },
  {
    listTitle: "old sketch samples",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "friendzone sketches",
        image: "/assets/scripts/FZsketches.pdf",
      },
      {
        title: "torture sketch",
        image: "/assets/scripts/TortureSketch.pdf"
      },
      {
        title: "in a relationship sketch",
        image: "/assets/scripts/InARelationshipSketch.pdf"
      },
      // {
      //   title: "daycare noir sketch",
      //   image: "/assets/scripts/DayCareNoirSketch.pdf"
      // },
    ]
  },
  {
    listTitle: "game show scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "slingtv multiplayer eps 5-6",
        image: "/assets/scripts/MULTIPLAYEReps5-6.pdf",
      },
      {
        title: "slingtv multiplayer eps 7-8",
        image: "/assets/scripts/MULTIPLAYEReps7-8.pdf",
      },
      {
        title: "slingtv multiplayer eps 9-10",
        image: "/assets/scripts/MULTIPLAYEReps9-10.pdf",
      },
    ]
  },
  {
    listTitle: "comic collaborations",
    tags: ['all', 'collabs', 'writing'],
    samples: [
      {
        title: "first in morale",
        image: "/assets/comiccollabs/FirstInMorale.pdf",
      },
      {
        title: "consequential",
        image: "/assets/comiccollabs/Consequential.pdf",
      },
      {
        title: "mr. monster, art by jess reilly",
        image: "/assets/comiccollabs/MrMonster.pdf",
      }
    ]
  }
];


export const linkProjects = [
  {
    listTitle: "animation scripting: lore in a minute",
    tags: ['all', 'writing', 'collabs'],
    samples: [
      {
        title: "forgotten realms lore video link",
        image: "/assets/anishorts/FRlore.jpg",
        link: "https://www.youtube.com/watch?v=_MC--auSi-w"
      },
      {
        title: "futurama lore video link",
        image: "/assets/anishorts/FUTlore.jpg",
        link: "https://www.youtube.com/watch?v=Sd1qz16fhwk&t=17s"
      },
      {
        title: "jojos bizarre adventure lore video link",
        image: "/assets/anishorts/JJBAlore.jpg",
        link: "https://www.youtube.com/watch?v=v2wGZBjvcvo"
      },
      {
        title: "katamari damacy lore video link",
        image: "/assets/anishorts/KDlore.jpg",
        link: "https://www.youtube.com/watch?v=RdkkSYqClyk"
      },
      {
        title: "mortal kombat x lore video link",
        image: "/assets/anishorts/MKXlore.jpg",
        link: "https://www.youtube.com/watch?v=b052IbtKaKs"
      },
      {
        title: "mobile suit gundam lore video link",
        image: "/assets/anishorts/MSGlore.jpg",
        link: "https://www.youtube.com/watch?v=ItRwkMDI8Qg"
      },
      {
        title: "one punch man lore video link",
        image: "/assets/anishorts/OPMlore.jpg",
        link: "https://www.youtube.com/watch?v=NSWtFS4nwuA"
      },
      {
        title: "gurren lagann lore video link",
        image: "/assets/anishorts/TTGLlore.jpg",
        link: "https://www.youtube.com/watch?v=erDrgWIJkbw&t=3s"
      },
      {
        title: "yu-gi-oh lore video link",
        image: "/assets/anishorts/YGOlore.jpg",
        link: "https://www.youtube.com/watch?v=osfWvaRAuM4&t=16s"
      }
    ]
  }
];


export const education = [
  {
    listTitle: "university education",
    samples: [
      {
        institution: "University of California, San Diego",
        imgSrc: "/assets/eduicons/UCSDlogo.png",
        imgTitle: "UCSD logo",
        summary: "Department of Communication PhD Program",
        details: "Advisor: Dr. Erin Hill",
      },
      {
        institution: "Ithaca College",
        imgSrc: "/assets/eduicons/IClogo.jpg",
        imgTitle: "IC logo",
        summary: "Park School of Communication",
        details: "B.S. Communications, Television / Radio Production",
      },
    ]
  },
  {
    listTitle: "creative education",
    samples: [
      {
        institution: "Upright Citizens Brigade",
        imgSrc: "/assets/eduicons/UCBlogo.jpg",
        imgTitle: "UCB logo",
        summary: "Sketch Writing 101 and 102",
        details: "Instructor: Neil Campbell",
      },
      {
        institution: "La Jolla Athenaeum",
        imgSrc: "/assets/eduicons/LJAlogo.png",
        imgTitle: "Athenaeum logo",
        summary: "Quick Sketch Life Drawing",
        details: "Instructor: Marty Davis",
      },
      {
        institution: "Smarter Art School",
        imgSrc: "/assets/eduicons/sas-logo.jpg",
        imgTitle: "Smarter Art School logo",
        summary: "Drawing Skills / Illustration",
        details: "Instructor: Eric Wilkerson",
      },
      {
        institution: "UCSD Extended Studies",
        imgSrc: "/assets/eduicons/UCSDXlogo.jpeg",
        imgTitle: "UCSD Extended Studies logo",
        summary: "Drawing 1: Focus on Perception",
        details: "Instructor: Kelsey Worth",
      },
      {
        institution: "Feminist Comic Book Club of San Diego",
        imgSrc: "/assets/eduicons/FCBClogo.jpg",
        imgTitle: "FCBC logo",
        summary: "Weekly Creative Workshop (2016-present)",
        details: "Organizer: Michael Gainey",
      },
      // {
      //   institution: "Scott McCloud's 24hr Comic Challenge",
      //   imgSrc: "/assets/eduicons/24Clogo.jpg",
      //   imgTitle: "24hr Comic logo",
      //   summary: "3x Participant and Host",
      //   details: "Create a 24 page comic in 24 hours!",
      // },
    ]
  },
  {
    listTitle: "software development",
    samples: [
      {
        institution: "Codeworks.me",
        imgSrc: "/assets/eduicons/CWlogo.png",
        imgTitle: "Codeworks logo",
        summary: "Software Development Immersive",
        details: "A competitive program with a 3% acceptance rate.",
      },
      {
        institution: "AlgoExpert.io",
        imgSrc: "/assets/eduicons/AElogo.jpg",
        imgTitle: "AlgoExpert logo",
        summary: "FrontendExpert Certification",
        details: "ID: a35557c297",
      },
      {
        institution: "Frontend Masters",
        imgSrc: "/assets/eduicons/FEMlogo.png",
        imgTitle: "Frontend Masters logo",
        summary: "Beginner & Pro Learning Paths",
        details: "Instructors: Brian Holt, Will Sentance, Jen Kramer",
      },
    ]
  },
  {
    listTitle: "miscellaneous",
    samples: [
      {
        institution: "Wild Willow Farm and Education Center",
        imgSrc: "/assets/eduicons/WWFEClogo.jpg",
        imgTitle: "Wild Willow Farm logo",
        summary: "Regenerative Farming 101 and 102",
        details: "Instructor: Paul Maschka",
      },
    ]
  },
  {
    listTitle: "career experience",
    samples: [
      {
        institution: "Maker Studios",
        imgSrc: "/assets/eduicons/MSlogo.jpeg",
        imgTitle: "Maker Studios logo",
        summary: "Writer and Editor",
        details: "Animation, Sketch, and Game writing for Lore in a Minute, Friendzone, Multiplayer, and more",
      },
      {
        institution: "Upper Deck Entertainment",
        imgSrc: "/assets/eduicons/UDlogo.png",
        imgTitle: "Upper Deck logo",
        summary: "Project Coordinator, Copy Editor, Game Tester",
        details: "Card and Game products for Marvel, Miramax, 20th Century Fox, James Bond, etc.",
      },
      {
        institution: "Pee-wee's Big Holiday",
        imgSrc: "/assets/eduicons/PWBHlogo.jpg",
        imgTitle: "Pee-wee logo",
        summary: "Assistant to Paul Reubens",
        details: "Pre-Production and Production of the Netflix film",
      },
      {
        institution: "Futurama",
        imgSrc: "/assets/eduicons/Futuramalogo.jpg",
        imgTitle: "Futurama logo",
        summary: "Writers Production Assistant",
        details: "The Emmy Winning Season 6",
      },
      {
        institution: "Fox Television Animation",
        imgSrc: "/assets/eduicons/FGlogo.png",
        imgTitle: "Family Guy logo",
        summary: "Assistant to Seth MacFarlane",
        details: "Family Guy, American Dad, The Cleveland Show, Ted, Seth and Alex's Almost Live Show, Cavalcade of Cartoon Comedy",
      },
      {
        institution: "The Ellen DeGeneres Show",
        imgSrc: "/assets/eduicons/Ellenlogo.jpg",
        imgTitle: "Ellen Degeneres logo",
        summary: "Production Assistant",
        details: "Season 5",
      },
      {
        institution: "Last Call with Carson Daly",
        imgSrc: "/assets/eduicons/LCCDlogo.png",
        imgTitle: "Last Call logo",
        summary: "Intern",
        details: "Talent Department",
      },
    ]
  },
];


export const teaching = [
  {
    listTitle: "lecturing",
    samples: [
      {
        institution: "University of California, San Diego",
        imgSrc: "/assets/eduicons/UCSDlogo.png",
        imgTitle: "UCSD logo",
        department: "Warren College Writing Program",
        details: "Lecturer and Departmental Tutor",
        courses: [
          "WCWP 10B: Disney Animation and Ideology",
          "WCWP 10A: Climate Change Ethics",
          "WCWP 10A: Promises and Contradictions in American History",
          "WCWP 10B: Justice in American History",
          "WCWP 10B: Food Ethics"
        ],
      },
      // {
      //   institution: "University of California, San Diego",
      //   imgSrc: "/assets/eduicons/UCSDlogo.png",
      //   imgTitle: "UCSD logo",
      //   department: "Department of Communications",
      //   details: "Instructional Assistant",
      //   courses: [
      //     ""
      //   ],
      // },
      {
        institution: "San Diego State University",
        imgSrc: "/assets/eduicons/SDSUlogo.jpg",
        imgTitle: "SDSU logo",
        department: "Television, Film, and New Media",
        details: "Guest Lecturer",
        courses: [
          "TFM 430: History of Television",
        ],
      },
      {
        institution: "La Jolla Country Day School",
        imgSrc: "/assets/eduicons/LJCDSlogo.png",
        imgTitle: "LJCDS logo",
        department: "Visual Arts Program",
        details: "Guest Lecturer",
        courses: [
          "The Upper School Film Course",
        ],
      },
    ]
  },
];