import React, { useState, useEffect } from 'react';
import styles from '../Components/ArtCard.module.css';

// PopupImage component
const ArtCard = ({ src, alt }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to open the popup
  const openPopup = () => setIsOpen(true);

  // Function to close the popup
  const closePopup = () => setIsOpen(false);

    useEffect(() => {
    }, [setIsOpen])

  return (
    <>
    {/* <div className={styles.artCard}> */}
      {/* Image that triggers the popup */}
      <img 
        src={src}
        alt={alt} 
        onClick={openPopup}
        loading="lazy"
        className={styles.artCard}
      />
      {/* </div> */}

      {/* Popup (modal) */}
      {isOpen && (
        <div className={styles.overlay} onClick={closePopup}>
          <div className={styles.popup}>
            <button className={styles.closeButton} onClick={closePopup}>
              X
            </button>
            <img src={src} alt={alt} className={styles.popupImage} />
          </div>
        </div>
      )}
    </>
  );
};



export default ArtCard;
