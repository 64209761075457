import { useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TheatersIcon from '@mui/icons-material/Theaters';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import styles from './Contact.module.css';

const About = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function encode(data:any) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => alert("Message sent!"))
      .catch((error) => alert(error));
  }

  const socials = {
    LinkedIn: 'https://www.linkedin.com/in/that-michael-gainey',
    Github: 'https://github.com/gainesaurus',
    Imdb: 'https://www.imdb.com/name/nm3377511/',
    Instagram: 'https://www.instagram.com/hugo_pines_comic/',
    Bluesky: 'https://bsky.app/profile/mrgcomics.bsky.social',
  }

  return (
    <section className={styles.container}>
      <div>
        <img src='/assets/FarmMG.png' className={styles.aboutPic} alt='Mike in a Farmhat' />
        <div className={styles.socialIconList}>
          <a href={socials.LinkedIn} className={styles.socialLink} target='_blank' rel='noreferrer' ><LinkedInIcon fontSize="large" /></a>
          <a href={socials.Github} className={styles.socialLink} target='_blank' rel='noreferrer' ><GitHubIcon fontSize="large" /></a>
          <a href={socials.Imdb} className={styles.socialLink} target='_blank' rel='noreferrer' ><TheatersIcon fontSize="large" /></a>
          <a href={socials.Instagram} className={styles.socialLink} target='_blank' rel='noreferrer' ><InstagramIcon fontSize="large" /></a>
          <a href={socials.Bluesky} className={styles.socialLink} target='_blank' rel='noreferrer' ><TwitterIcon fontSize="large" /></a>
        </div>
      </div>

      <div className={styles.contactContainer}>
        <h2 className={styles.heading}>contact:</h2>
        <form
          data-netlify="true"
          name="contact"
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <div className={styles.formField}>
            <label htmlFor="name" className={styles.label}>
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              className={styles.nameInput}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="email" className={styles.label}>
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your E-Mail"
              className={styles.emailInput}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label
              htmlFor="message"
              className={styles.label}
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              className={styles.msgInput}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className={styles.submitBtn}
          >
            Submit
          </button>
        </form>
      </div>
      
    </section>
  )
}

export default About;